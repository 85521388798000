const ObjectMapping = [
    {
        object: 'App\\Models\\Users',
        router_link: '/employee/{id}'
    },
    {
        object: 'App\\Models\\Contract\\Contract',
        router_link: '/contracts/{id}'
    },
    {
        object: 'Spatie\\Permission\\Models\\Role',
        router_link: '/permissions'
    },
    {
        object: 'App\\Models\\Client\\Contact',
        router_link: '/contacts/{id}'
    },
    {
        object: 'App\\Models\\Client\\Client',
        router_link: '/customers/{id}'
    },
    {
        object: 'App\\Models\\Order\\Order',
        router_link: '/invoices/view/{id}'
    },
];

const getObjectLink = (object, id) => {
    let objectMap = ObjectMapping.find(item => item.object == object);

    if(!objectMap) {
        return '/';
    }

    return objectMap.router_link.replace("{id}", id);
}

export default getObjectLink;
