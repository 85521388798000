<template>
    <div>
        <vx-card>
            <vue-bootstrap4-table :rows="data" :columns="columns" :config="config" @on-change-query="onChangeQuery" :total-rows="total_rows">
                <template slot="user_name" slot-scope="props">
                    <span class="vbt-table-td">
                        <b>{{props.row.user.detail.first_name}} {{props.row.user.detail.last_name}}</b>
                    </span>
                </template>
                <template slot="event_type" slot-scope="props">
                    <span class="vbt-table-td" :class="{
                     'text-danger':  props.row.event_type === 'delete'
                    }">
                        {{props.row.event_type}}
                    </span>
                </template>
                <template slot="id_link" slot-scope="props">
                    <span class="vbt-table-td" v-if="props.row.event_type !== 'delete'">
                        <router-link :to="getLink(props.row.object_type, props.row.object_id)">{{props.row.object_id}}</router-link>
                    </span>
                    <span class="vbt-table-td" v-else>
                        {{props.row.object_id}}
                    </span>
                </template>
                <template slot="row_actions" slot-scope="props">
                    <vs-button color="warning" type="filled" icon="edit" size="small"
                               :to="'/contract/'+props.row.id"></vs-button>
                </template>
                <template slot="empty-results">
                    Keine Aktivitäten gefunden.
                </template>
            </vue-bootstrap4-table>
        </vx-card>
    </div>
</template>

<script>
    import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'
    import ApiService from "../../api";
    import moment from 'moment';
    import _ from 'lodash';
    import getObjectLink from './object-mapping'

    var qs = require('qs');
    import QueryHelper from "../../mixins/helper/query.helper";

    export default {
        name: "Activities",
        components: {
            VueBootstrap4Table
        },
        data() {
            return {
                data: [],
                mgselect: 3,
                columns: [
                    {
                        label: "Typ",
                        name: "event_type",
                        width: "200px",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                        slot_name: 'event_type'
                    },
                    {
                        label: "Objekt",
                        name: "object_type",
                        width: "280px",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                    }, {
                        label: "Objekt ID",
                        name: "object_id",
                        width: "140px",
                        minWidth: "140px",
                        filter: {
                            type: "simple",
                            placeholder: "Suche..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                        slot_name: 'id_link'
                    }, {
                        label: "Beschreibung",
                        name: "event_description",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                    }, {
                        label: "Benutzer",
                        name: "user.detail.first_name",
                        slot_name: "user_name",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                    }, {
                        label: "Ausgeführt",
                        name: "created_at",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        formatter: (value) => {
                            return moment(new Date(value)).format('HH:mm - D.MM.YY');
                        },
                        showCol: true,
                        initial_sort: true,
                        initial_sort_order: 'desc',
                    }, {
                        label: "Actions",
                        slot_name: "row_actions",
                        sort: false,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'actions-column',
                        showCol: true,
                    }],
                config: {
                    checkbox_rows: false,
                    show_refresh_button: false,
                    show_reset_button: false,
                    highlight_row_hover_color: '#f8f8f8',
                    server_mode: true
                },
                queryParams: {
                    sort: [],
                    filters: [],
                    global_search: "",
                    per_page: 10,
                    page: 1,
                },
                total_rows: 0,

            }
        },
        computed: {
            requestParams() {
                return QueryHelper.parseRequestParams(this.queryParams);
            },
        },
        created() {
            //this.fetchData()
        },
        methods: {
            getLink: getObjectLink,

            onChangeQuery(queryParams) {
                this.queryParams = queryParams;
                this.fetchData();
            },

            fetchData() {
                this.$vs.loading()

                ApiService.get('activities', {
                    params: this.requestParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then(response => {
                    this.data = response.data.result.result;
                    this.total_rows = response.data.result.total;
                    this.$vs.loading.close()
                })
            },

            formatDate(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            }
        },
    }
</script>

<style scoped>

</style>
